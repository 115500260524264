import React from "react";
import UseWindowSize from "../../../assets/functions/UseWindowSize/UseWindowSize";

//components
import InformationCard from "../../../components/InformationCard/InformationCard";
import InformationCardMobile from "../../../components/InformationCardMobile/InformationCardMobile";

//svgs
import LeftBox from "../../../assets/svgs/leftBox.svg";
import svgOperatingSystem from "../../../assets/svgs/pag_sistemas_web.svg";
import svgSistWeb from "../../../assets/svgs/servicos_sistema_web.svg";

export default function SistemasWeb() {
  const size = UseWindowSize();
  const title = "Sistemas Web";
  const description =
    "O ambiente perfeito para sua empresa administrar e gerir da melhor forma todas as informações cotidianas e necessidades. Venha desenvolver conosco um sistema Web para o seu negócio!";

  return (
    <div id="sistemas-web">
      {size.width >= 480 ? (
        <div className="container-fluid pl-0">
          <div className="row">
            <div className="col-md-7 col-sist-web">
              <InformationCard
                direction="left"
                src={LeftBox}
                topRightIcon={svgSistWeb}
                divClassName="box-left"
                title={title}
                description={description}
              />
            </div>
            <div className="col-md-5 d-none d-md-flex">
              <div className="container-content">
                <img src={svgOperatingSystem} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <InformationCardMobile title={title} description={description} />
      )}
    </div>
  );
}
