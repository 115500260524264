import React from "react";
import "./Card.css";

export default function Card(props) {
  return (
    <div className="card-shadow container-card">
      <h4>{props.title}</h4>
      <div className="container container-card-img">
        <img
          src={props.img}
          alt={props.title}
          width={props.imgWidth}
          draggable="false"
        />
      </div>
      <p>{props.text}</p>
    </div>
  );
}
