import React from "react";
import UseWindowSize from "../../../assets/functions/UseWindowSize/UseWindowSize";

//components
import InformationCard from "../../../components/InformationCard/InformationCard";
import InformationCardMobile from "../../../components/InformationCardMobile/InformationCardMobile";
import DeviceNotebook from "../../../components/Devices/DeviceNotebook";
import Portfolio from "../../../components/Portfolio/Portfolio";

//imgs + svgs + icons
import LeftBox from "../../../assets/svgs/leftBox.svg";
import svgSite from "../../../assets/svgs/servicos_site.svg";
import imgEngaj from "../../../assets/img/imgEngaj.png";
import imgShirley from "../../../assets/img/imgShirley.png";
import imgSeizer from "../../../assets/img/imgSeizer.png";

function RenderPortfolio() {
  return (
    <div className="container-content d-flex justify-content-center mr-0 mr-md-3 pb-5 pb-md-0">
      <DeviceNotebook>
        <Portfolio>
          <div>
            <a
              href="https://engajconsultoria.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={imgEngaj} alt="" width="100%" />
              </div>
            </a>
          </div>
          <div>
            <a
              href="https://shirleycanabrava.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={imgShirley} alt="" width="100%" />
              </div>
            </a>
          </div>
          <div>
            <a
              href="https://seizer.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={imgSeizer} alt="" width="100%" />
              </div>
            </a>
          </div>
        </Portfolio>
      </DeviceNotebook>
    </div>
  );
}

export default function Sites() {
  const size = UseWindowSize();
  const title = "Sites";
  const description =
    "Expanda seu negócio para o meio digital, organize seus serviços e muito mais através de um portfólio online. Alcance mais pessoas e lugares mostrando sua empresa para o mundo!";

  return (
    <div id="sites">
      {size.width >= 480 ? (
        <div className="container-fluid pl-0 pt-5">
          <div className="row">
            <div className="col-md-7">
              <InformationCard
                direction="left"
                src={LeftBox}
                topRightIcon={svgSite}
                divClassName="box-left"
                title={title}
                description={description}
              />
            </div>
            <div className="col-md-5 d-flex justify-content-center justify-content-md-end">
              <RenderPortfolio />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <InformationCardMobile title={title} description={description} />
          <div className="container-fluid pt-5">
            <RenderPortfolio />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
