import React from "react";
import "./Parceiros.css";

import imgBj from "../../../assets/img/logoBj.png";
import imgFejesp from "../../../assets/img/logoFejesp.png";
import imgUnesp from "../../../assets/img/logoUnesp.png";
import imgNucleo from "../../../assets/img/logoNucleoSc.png";

export default function Parceiros() {
  function goTo(link) {
    window.open(link);
  }

  return (
    <div className="container-fluid container-content text-center py-5">
      <div className="row mb-3 mb-md-5">
        <div className="col-12">
          <h1>Nossos parceiros</h1>
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        <div className="col-sm-3 mb-5 mb-sm-0">
          <div
            className="card-shadow card-partners card-link"
            onClick={() => goTo("https://brasiljunior.org.br/")}
          >
            <div className="container-img">
              <img src={imgBj} alt="Logo Brasil Júnior" draggable="false" />
            </div>
          </div>
        </div>
        <div className="col-sm-3 mb-5 mb-sm-0">
          <div
            className="card-shadow card-partners card-link"
            onClick={() => goTo("https://www.instagram.com/_fejesp/")}
          >
            <div className="container-img">
              <img src={imgFejesp} alt="Logo FEJESP" draggable="false" />
            </div>
          </div>
        </div>
        <div className="col-sm-3 mb-5 mb-sm-0">
          <div
            className="card-shadow card-partners card-link"
            onClick={() => goTo("https://igce.rc.unesp.br/")}
          >
            <div className="container-img">
              <img src={imgUnesp} alt="Logo Unesp" draggable="false" />
            </div>
          </div>
        </div>
        <div className="col-sm-3 mb-5 mb-sm-0">
          <div
            className="card-shadow card-partners card-link"
            onClick={() => goTo("https://nucleosaocarlos.org.br/")}
          >
            <div className="container-img">
              <img
                src={imgNucleo}
                alt="Logo Núcleo São Carlos"
                draggable="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
