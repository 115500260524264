import React from "react";
import { HashLink } from "react-router-hash-link";
import ScrollWithOffset from "../../../assets/functions/ScrollWithOffset/ScrollWithOffset";
import Card from "../../../components/Card/Card.js";
import "./Servicos.css";

//svgs
import svgSite from "../../../assets/svgs/servicos_site.svg";
import svgApp from "../../../assets/svgs/servicos_app.svg";
import svgSistWeb from "../../../assets/svgs/servicos_sistema_web.svg";

export default function Servicos() {
  return (
    <div className="container-fluid container-content text-center py-5">
      <div className="row">
        <div className="col-12 mb-5">
          <h1>Conheça nossos serviços</h1>
        </div>
      </div>
      <div className="row d-flex d-flex justify-content-between">
        <div className="col-md-4 mb-5 mb-md-0 card-servico card-link">
          <HashLink
            to="/servicos/#sites"
            scroll={(el) => ScrollWithOffset(el, false)}
          >
            <Card
              title="Sites"
              img={svgSite}
              imgWidth="70%"
              text="O portfólio ideal para quem quer trazer seu negócio para o meio digital. Mostre para o
              mundo o potencial da sua empresa com a gente!"
            />
          </HashLink>
        </div>
        <div className="col-md-4 mb-5 mb-md-0 card-servico card-link">
          <HashLink
            to="/servicos/#aplicativos"
            scroll={(el) => ScrollWithOffset(el, false)}
          >
            <Card
              title="Aplicativos"
              img={svgApp}
              imgWidth="60%"
              text="Criamos aplicativos sob medida para atender você e suas necessidades.
              Atendemos em todas as plataformas mobile."
            />
          </HashLink>
        </div>
        <div className="col-md-4 mb-5 mb-md-0 card-servico card-link">
          <HashLink
            to="/servicos/#sistemas-web"
            scroll={(el) => ScrollWithOffset(el, false)}
          >
            <Card
              title="Sistemas Web"
              img={svgSistWeb}
              imgWidth="70%"
              text="Qual a sua ideia?
              Aqui colocaremos em prática o seu serviço digitalizado. Vem falar com a gente!"
            />
          </HashLink>
        </div>
      </div>
    </div>
  );
}
