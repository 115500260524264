import React from "react";
import CardMembro from "../../../components/CardMembro/CardMembro.js";

import membros from "../../../assets/membros.json";

function RenderMembros() {
  //const baseUrl = "https://infojrunesp.com.br";

  membros.sort(function (a, b) {
    a = a.name.toLowerCase();
    b = b.name.toLowerCase();

    return a < b ? -1 : a > b ? 1 : 0;
  });

  return membros.map((membro) => {
    return (
      <div className="col-lg-3 col-sm-4 mt-3 mt-md-5" key={membro.id}>
        <CardMembro
          img={`/img/${membro.img}`}
          name={membro.name}
          role={membro.role}
          github={membro.github}
          linkedin={membro.linkedin}
        />
      </div>
    );
  });
}

export default function Equipe() {
  return (
    <div className="container-fluid container-content py-3 py-md-5" id="equipe">
      <div className="row text-center">
        <div className="col-12">
          <h1>Nossa Equipe</h1>
        </div>
      </div>
      <div className="row">
        <RenderMembros />
      </div>
    </div>
  );
}
