import React from "react";
import UseWindowSize from "../../assets/functions/UseWindowSize/UseWindowSize";
import svgServices from "../../assets/svgs/services.svg";
import Navbar from "../../components/Navbar/Navbar";
import PageIntro from "../../components/PageIntro/PageIntro";
import Sites from "./Sites/Sites";
import Aplicativos from "./Aplicativos/Aplicativos";
import SistemasWeb from "./SistemasWeb/SistemasWeb";
import Contato from "../../components/Contato/Contato";
import Footer from "../../components/Footer/Footer";

function Servicos() {
  const size = UseWindowSize();
  return (
    <React.Fragment>
      <Navbar />
      <PageIntro
        title="Serviços"
        description="O que você busca? Um site para falar sobre sua empresa? 
        Talvez um aplicativo para a sua ideia? Ou um sistema web para o seu negócio? 
        Aqui na Info Jr temos o que é necessário para essas aplicações de maneira 
        segura e com a qualidade do mercado de trabalho."
        link1="/servicos/#sites"
        link1Name="Sites"
        link2="/servicos/#aplicativos"
        link2Name="Aplicativos"
        link3="/servicos/#sistemas-web"
        link3Name="Sistemas Web"
        imgPath={svgServices}
      />
      {size.width < 480 ? (
        <div className="container-fluid container-content my-5">
          <img src={svgServices} alt="Serviços" width="100%" />
        </div>
      ) : null}
      <Sites />
      <Aplicativos />
      <SistemasWeb />
      <Contato />
      <Footer />
    </React.Fragment>
  );
}

export default Servicos;
