import React from "react";
import "./Mvv.css";
import Card from "../../../components/Card/Card.js";

//svgs
import svgMissao from "../../../assets/svgs/missao.svg";
import svgVisao from "../../../assets/svgs/visao.svg";
import svgValores from "../../../assets/svgs/valores.svg";

export default function Mvv() {
  return (
    <div className="container-fluid container-content text-center py-3 py-md-5">
      <div className="row">
        <div className="col-12 mb-3 mb-md-5">
          <h1>Saiba mais sobre a Info Jr.</h1>
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        <div className="col-md-4 mb-5 mb-md-0 card-mvv">
          <Card
            title="Missão"
            img={svgMissao}
            imgWidth="70%"
            text="Trazer qualidade e confiabilidade aos projetos desenvolvidos."
          />
        </div>
        <div className="col-md-4 mb-5 mb-md-0 card-mvv">
          <Card
            title="Visão"
            img={svgVisao}
            imgWidth="60%"
            text="Impactar a sociedade com ideias inovadoras, visando sempre o crescimento dos
            projetos."
          />
        </div>
        <div className="col-md-4 mb-5 mb-md-0 card-mvv">
          <Card
            title="Valores"
            img={svgValores}
            imgWidth="70%"
            text="Satisfação ao cliente, respeito e honestidade são as bases dos nossos serviços."
          />
        </div>
      </div>
    </div>
  );
}
