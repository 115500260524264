import React from "react";
import UseWindowSize from "../../../assets/functions/UseWindowSize/UseWindowSize";

//components
import InformationCard from "../../../components/InformationCard/InformationCard";
import DeviceCellphone from "../../../components/Devices/DeviceCellphone";
import Portfolio from "../../../components/Portfolio/Portfolio";

//imgs + svgs + icons
import RightBox from "../../../assets/svgs/rightBox.svg";
import svgApp from "../../../assets/svgs/servicos_app.svg";
import imgiCampus from "../../../assets/img/imgiCampus.png";
import imgSge from "../../../assets/img/imgSge.png";
import InformationCardMobile from "../../../components/InformationCardMobile/InformationCardMobile";

function RenderPortfolio() {
  return (
    <div className="container-content mb-0 mb-md-3 ml-0 ml-md-3">
      <DeviceCellphone>
        <Portfolio>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.icampus&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={imgiCampus} alt="" width="100%" />
              </div>
            </a>
          </div>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.segespqrcodereader&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={imgSge} alt="" width="100%" />
              </div>
            </a>
          </div>
        </Portfolio>
      </DeviceCellphone>
    </div>
  );
}

export default function Aplicativos() {
  const size = UseWindowSize();
  const title = "Aplicativos";
  const description =
    "Suas ideias inovadoras em prática! Fale com a gente para encontrarmos a melhor solução dos seus problemas na forma de um aplicativo funcional, visando a qualidade e viabilidade!";

  return (
    <div id="aplicativos">
      {size.width >= 480 ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 order-md-12 pr-0 d-flex justify-content-end">
              <InformationCard
                id="teste"
                direction="right"
                src={RightBox}
                topLeftIcon={svgApp}
                divClassName="box-right"
                title={title}
                description={description}
              />
            </div>
            <div className="col-md-5 order-md-1 d-flex justify-content-center justify-content-md-start">
              <RenderPortfolio />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <InformationCardMobile title={title} description={description} />
          <div className="container-fluid d-flex justify-content-center py-5">
            <RenderPortfolio />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
