import React from "react";
import "./PageIntro.css";
import UseWindowSize from "../../assets/functions/UseWindowSize/UseWindowSize";
import ScrollWithOffset from "../../assets/functions/ScrollWithOffset/ScrollWithOffset";
import { HashLink } from "react-router-hash-link";

//components
import NavBar from "../../components/Navbar/Navbar";

//svg
import svgWavesIntro from "../../assets/svgs/wavesPageIntro.svg";

export default function PageIntro(props) {
  const size = UseWindowSize();

  return (
    <React.Fragment>
      <div className="container-gradient white-text position-relative">
        <img
          src={svgWavesIntro}
          alt=""
          width="100%"
          id="imgWavesIntro"
          draggable="false"
        />
        <NavBar />
        <div className="container-fluid container-content container-top">
          <div className="row">
            <div className="col-md-7">
              <h1>{props.title}</h1>
              <div className="px-0 py-3 py-md-5">
                <p className="text-justify">{props.description}</p>
              </div>
              {size.width >= 410 ? (
                <div className="container-buttons d-flex justify-content-between pb-3">
                  <HashLink
                    smooth
                    to={props.link1}
                    className="intro-link"
                    scroll={(el) => ScrollWithOffset(el, true)}
                  >
                    {props.link1Name}
                  </HashLink>
                  <hr className="w-100" />
                  <HashLink
                    smooth
                    to={props.link2}
                    className="intro-link"
                    scroll={(el) => ScrollWithOffset(el, true)}
                  >
                    {props.link2Name}
                  </HashLink>
                  <hr className="w-100" />
                  <HashLink
                    smooth
                    to={props.link3}
                    className="intro-link"
                    scroll={(el) => ScrollWithOffset(el, true)}
                  >
                    {props.link3Name}
                  </HashLink>
                </div>
              ) : (
                <div className="container-buttons">
                  <div className="row">
                    <div className="col-12 px-0 d-flex justify-content-between">
                      <hr className="w-100" />
                      <HashLink
                        smooth
                        to={props.link1}
                        className="intro-link"
                        scroll={(el) => ScrollWithOffset(el, true)}
                      >
                        {props.link1Name}
                      </HashLink>
                      <hr className="w-100" />
                    </div>
                    <div className="col-12 px-0 d-flex justify-content-between">
                      <hr className="w-100" />
                      <HashLink
                        smooth
                        to={props.link2}
                        className="intro-link"
                        scroll={(el) => ScrollWithOffset(el, true)}
                      >
                        {props.link2Name}
                      </HashLink>
                      <hr className="w-100" />
                    </div>
                    <div className="col-12 px-0 d-flex justify-content-between">
                      <hr className="w-100" />
                      <HashLink
                        smooth
                        to={props.link3}
                        className="intro-link"
                        scroll={(el) => ScrollWithOffset(el, true)}
                      >
                        {props.link3Name}
                      </HashLink>
                      <hr className="w-100" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-5 pl-5 d-none d-md-flex">
              <img
                src={props.imgPath}
                width="100%"
                alt={props.title}
                draggable="false"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
