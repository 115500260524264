import React from "react";
import "./Devices.css";

export default function Devices(props) {
  return (
    <div className="marvel-device macbook">
      <div className="top-bar"></div>
      <div className="camera"></div>
      <div className="screen">{props.children}</div>
      <div className="bottom-bar"></div>
    </div>
  );
}
