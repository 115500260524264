import React from "react";
import "./BanneriCampus.css";
import DeviceCellphone from "../../../components/Devices/DeviceCellphone";

//svg
import svgLogoICampus from "../../../assets/svgs/logo_icampus.svg";
import imgBtnGoogle from "../../../assets/svgs/btn_googleplay.svg";
import imgTela from "../../../assets/img/imgiCampus.png";

export default function ICampus() {
  function goToiCampus() {
    window.open("https://icampus-infojr.com.br/");
  }

  function goToPlayStore() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.icampus&hl=pt_BR&gl=US"
    );
  }

  return (
    <div className="container-fluid container-content my-0 my-lg-5 py-3 py-md-5">
      <div className="row">
        <div className="col-lg-7">
          <div className="card-shadow">
            <div className="row text-sm-left text-center">
              <div className="col-sm-7 mb-5 mb-sm-0">
                <h4>Conheça o iCampus</h4>
                <div className="container-fluid px-0 py-3">
                  <p className="text-justify">
                    Aplicativo desenvolvido pelos integrantes da Info Jr. para
                    integrar o campus Rio Claro da UNESP.
                  </p>
                </div>
                <button type="button" className="button" onClick={goToiCampus}>
                  <img
                    src={svgLogoICampus}
                    alt="iCampus"
                    height="30"
                    draggable="false"
                  />
                  Visite o site
                </button>
              </div>
              <div className="col-sm-5 text-center">
                <div className="container-fluid">
                  <h4>Baixe agora</h4>
                  <button
                    type="button"
                    className="btnGooglePlay mt-3"
                    onClick={goToPlayStore}
                  >
                    <img
                      src={imgBtnGoogle}
                      alt="Google Play"
                      width="100%"
                      draggable="false"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 d-none d-lg-flex justify-content-end">
          <div className="container-cellphone">
            <DeviceCellphone>
              <img src={imgTela} alt="Tela iCampus" width="100%" />
            </DeviceCellphone>
          </div>
        </div>
      </div>
    </div>
  );
}
