import React from "react";
//svgs
import svgAboutUs from "../../assets/svgs/aboutUs.svg";

//components
import PageIntro from "../../components/PageIntro/PageIntro";
import Empresa from "./Empresa/Empresa";
import Mvv from "./Mvv/Mvv";
import Mej from "./Mej/Mej";
import Equipe from "./Equipe/Equipe";
import Parceiros from "./Parceiros/Parceiros";
import Footer from "../../components/Footer/Footer";

export default function SobreNos() {
  return (
    <React.Fragment>
      <PageIntro
        title="Sobre Nós"
        description="Somos uma empresa júnior (EJ) do curso bacharelado em Ciências da Computação
        da Universidade Estadual Paulista “Júlio de Mesquita Filho” de Rio Claro. Com pessoas focadas
        e competentes para desenvolver o seu projeto, prezamos pela qualidade e confiabilidade, além
        de um ambiente de aprendizado adequado."
        link1="/sobrenos/#info"
        link1Name="A InfoJr."
        link2="/sobrenos/#mej"
        link2Name="MEJ"
        link3="/sobrenos/#equipe"
        link3Name="Equipe"
        imgPath={svgAboutUs}
      />
      <Empresa />
      <Mvv />
      <Mej />
      <Equipe />
      <Parceiros />
      <Footer />
    </React.Fragment>
  );
}
