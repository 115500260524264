import React from "react";
import "./InformationCard.css";

export default function InformationCard(props) {
  return (
    <div className="container-box mb-5 mb-md-3 mb-lg-5">
      <img
        className={props.direction}
        src={props.src}
        height="100%"
        alt=""
        draggable="false"
      />

      {props.topRightIcon !== "" ? (
        <div>
          <img
            className="top-right-icon"
            src={props.topRightIcon}
            width="30%"
            alt=""
            draggable="false"
          />
        </div>
      ) : null}

      {props.topLeftIcon !== "" ? (
        <div>
          <img
            className="top-left-icon"
            src={props.topLeftIcon}
            width="30%"
            alt=""
            draggable="false"
          />
        </div>
      ) : null}

      <div className={props.divClassName}>
        <div className="info-container white-text">
          <h1 className="mb-3 info-title">{props.title}</h1>
          <p className="text-justify">{props.description}</p>
          {props.children}
        </div>
      </div>
    </div>
  );
}
