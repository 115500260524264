import React from "react";

//svg
import svgEmpresa from "../../../assets/svgs/empresa.svg";

export default function Empresa() {
  return (
    <div
      className="container-fluid container-content pt-5 pb-3 pb-md-5"
      id="info"
    >
      <div className="row">
        <div className="col-lg-6 d-none pr-5 d-lg-flex">
          <img src={svgEmpresa} alt="Equipe" width="100%" draggable="false" />
        </div>
        <div className="col-lg-6">
          <h1>Inconformismo, pensamento inovador e capacidade de realização</h1>
          <div className="container-fluid px-0 pt-3 pt-lg-5">
            <p className="text-justify">
              Somos estudantes buscando impactar a vida das pessoas, com
              intenção de fazer a diferença seja onde estivermos, contando com
              um grupo seleto de membros que irão te atender para buscar a
              melhor solução das suas necessidades, sempre garantindo
              confiabilidade e qualidade para o seu projeto.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
