import React, { useState, useEffect } from "react";
import "./Navbar.css";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavbarToggler,
  Collapse,
  NavItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import ScrollWithOffset from "../../assets/functions/ScrollWithOffset/ScrollWithOffset";
import imgLogo from "../../assets/img/logo.png";

export default function NavBar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [navColor, setNavColor] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  function toggleNav() {
    setIsNavOpen(!isNavOpen);

    if (!isScrolled) {
      setTimeout(() => {
        setNavColor(!navColor);
      }, 120);
    }
  }

  useEffect(() => {
    const changeNavbar = () => {
      if (!isNavOpen) {
        if (window.scrollY >= 20) {
          setNavColor(true);
          setIsScrolled(true);
        } else {
          setNavColor(false);
          setIsScrolled(false);
        }
      } else setNavColor(navColor);
    };

    document.addEventListener("scroll", changeNavbar);

    return () => {
      document.removeEventListener("scroll", changeNavbar);
    };
  }, [isNavOpen, navColor]);

  return (
    <Navbar
      className={navColor ? "navbar-dark active" : "navbar-dark"}
      fixed="top"
      expand="lg"
    >
      <NavbarBrand href="/" className="nav-padding">
        <img src={imgLogo} height="50" alt="InfoJr." draggable="false" />
      </NavbarBrand>
      <NavbarToggler onClick={() => toggleNav()} />
      <Collapse isOpen={isNavOpen} navbar>
        <Nav navbar className="ml-auto">
          <NavItem className="nav-item">
            <NavLink className="nav-link" to="/servicos">
              Serviços
            </NavLink>
          </NavItem>
          <NavItem className="nav-item">
            <NavLink className="nav-link" to="/sobrenos">
              Sobre Nós
            </NavLink>
          </NavItem>
          <NavItem className="nav-item">
            <NavHashLink
              className="nav-link"
              activeClassName="active"
              to="/#contato"
              scroll={(el) => ScrollWithOffset(el, false)}
            >
              Contato
            </NavHashLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}
