import React from "react";
import UseWindowSize from "../../../assets/functions/UseWindowSize/UseWindowSize";
import "./Mej.css";

//components
import InformationCard from "../../../components/InformationCard/InformationCard";
import InformationCardMobile from "../../../components/InformationCardMobile/InformationCardMobile";

//svgs + imgs
import LeftBox from "../../../assets/svgs/leftBox.svg";
import LogoBJ from "../../../assets/img/logoBj.png";

export default function Membros() {
  function goToBJ() {
    window.open("https://brasiljunior.org.br/conheca-o-mej");
  }

  const size = UseWindowSize();
  const title = "MEJ";
  const description =
    "“O MEJ tem como propósito a busca por um Brasil Empreendedor. Trabalhamos todos os dias para formar pessoas comprometidas e capazes de transformar o Brasil por meio da realização de mais e melhores projetos. O movimento surgiu em 1967 na França e veio para o Brasil em 1988.”";

  return (
    <div id="mej">
      {size.width >= 480 ? (
        <div className="container-fluid pl-0 my-5 my-lg-3">
          <div className="row">
            <div className="col-lg-7 col-md-9 col-mej">
              <InformationCard
                direction="left"
                src={LeftBox}
                divClassName="box-left"
                title={title}
                description={description}
              >
                <button type="button" onClick={goToBJ} className="button">
                  Saiba Mais
                </button>
              </InformationCard>
            </div>

            <div className="col-lg-5 col-md-3 d-none d-md-flex">
              <img
                src={LogoBJ}
                alt="Logo Brasil Júnior"
                width="150%"
                draggable="false"
                id="logo-bj"
              />
            </div>
          </div>
        </div>
      ) : (
        <InformationCardMobile title={title} description={description}>
          <div className="container-fluid p-0 text-center">
            <button type="button" onClick={goToBJ} className="button mt-3">
              Saiba Mais
            </button>
          </div>
        </InformationCardMobile>
      )}
    </div>
  );
}
