import React from "react";

export default function InformationCardMobile(props) {
  return (
    <div className="container-gradient white-text py-5 my-3">
      <div className="container-fluid container-content">
        <h1 className="mb-3">{props.title}</h1>
        <p className="text-justify">{props.description}</p>
        {props.children}
      </div>
    </div>
  );
}
