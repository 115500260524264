import React from "react";
import Lottie from "react-lottie";
import { HashLink } from "react-router-hash-link";
import rocketAnimation from "../../../assets/lotties/rocket-desk.json";
import ScrollWithOffset from "../../../assets/functions/ScrollWithOffset/ScrollWithOffset";

export default function LandingPage() {
  const optionsAnim = {
    loop: true,
    autoplay: true,
    animationData: rocketAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="container-fluid container-content container-top">
      <div className="row">
        <div className="col-lg-6 col-sm-8 white-text">
          <h1>Sua ideia voando do papel</h1>
          <div className="container-fluid px-0 py-3 py-md-5">
            <p className="text-justify">
              Realize seu sonho e coloque sua ideia inovadora em prática,
              conquiste sua visibilidade digital, organize seu negócio, venda
              online e muito mais!
            </p>
          </div>
          <HashLink
            smooth
            to="/#intro"
            scroll={(el) => ScrollWithOffset(el, true)}
          >
            <button type="button" className="button">
              Saiba Mais
            </button>
          </HashLink>
        </div>
        <div className="col-lg-5 offset-lg-1 col-sm-4 d-none d-sm-block">
          <Lottie
            options={optionsAnim}
            width="100%"
            isClickToPauseDisabled={true}
          />
        </div>
      </div>
    </div>
  );
}
