import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "../assets/functions/ScrollToTop/ScrollToTop";

import Home from "../pages/Home/Home";
import Servicos from "../pages/Servicos/Servicos";
import SobreNos from "../pages/SobreNos/SobreNos";

export default function Routes() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/servicos" component={Servicos} />
        <Route path="/sobrenos" component={SobreNos} />
      </Switch>
    </BrowserRouter>
  );
}
