import React from "react";
import "./Footer.css";

//img
import imgLogo from "../../assets/img/logo.png";

//icons
import { ImLocation } from "react-icons/im";
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { RiFacebookCircleLine } from "react-icons/ri";
import { RiGithubFill } from "react-icons/ri";
import { RiLinkedinBoxLine } from "react-icons/ri";
import { RiInstagramLine } from "react-icons/ri";
import { RiWhatsappLine } from "react-icons/ri";

export default function Footer() {
  function goTo(link) {
    window.open(link);
  }

  return (
    <div className="container-footer">
      <div className="container-fluid">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14755.842334081215!2d-47.55251826352387!3d-22.392843333801824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd9eb254b9dd4c755!2sUnesp%20Campus%20Rio%20Claro!5e0!3m2!1spt-BR!2sbr!4v1601322546259!5m2!1spt-BR!2sbr"
          title="map"
          width="100%"
          height="400"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>

      <div className="container-fluid container-gradient">
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-lg-3 col-md-12 mb-5 mb-lg-0 col-center">
              <img src={imgLogo} height="80" alt="InfoJr." draggable="false" />
            </div>

            <div className="col-lg-3 col-md-4 mb-3 mb-lg-0 col-center">
              <div className="row pl-md-5">
                <h4 className="title">Endereço</h4>
              </div>
              <div className="row">
                <div className="container-fluid d-flex py-2">
                  <ImLocation
                    size={20}
                    color="#FFFFFF"
                    className="footer-icon"
                  />
                  <div className="container-address">
                    <p>Av. 24 A, nº 1515, Bela Vista,</p>
                    <p>Rio Claro - SP,</p>
                    <p>DEMAC - UNESP</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-8 col-center">
              <div className="row">
                <div className="col-12 col-lg-11 text-center">
                  <h4 className="title">Contato</h4>
                </div>
              </div>
              <div className="row py-2">
                <div className="col-lg-5 col-md-5 text-left" id="contact-left">
                  <p>
                    <FaPhoneAlt
                      size={20}
                      color="#FFFFFF"
                      className="footer-icon"
                    />{" "}
                    (19) 3526-9004
                  </p>
                  <p>
                    <FaPhoneAlt
                      size={20}
                      color="#FFFFFF"
                      className="footer-icon"
                    />{" "}
                    (19) 3526-9009
                  </p>
                </div>

                <div className="col-lg-7 col-md-3 text-left" id="contact-right">
                  <p>
                    <HiOutlineMail
                      size={40}
                      className="footer-icon icon-mail"
                    />{" "}
                    <a href="mailto:comunicacoes@infojrunesp.com.br">comunicacoes@infojrunesp.com.br</a>
                  </p>
                  <div className="row d-flex justify-content-between container-media">
                    <RiInstagramLine
                      size={40}
                      color="#FFFFFF"
                      className="footer-icon-media"
                      onClick={() => goTo("https://www.instagram.com/infojr/")}
                    />
                    <RiLinkedinBoxLine
                      size={40}
                      color="#FFFFFF"
                      className="footer-icon-media"
                      onClick={() =>
                        goTo("https://br.linkedin.com/company/infojr")
                      }
                    />
                    <RiGithubFill
                      size={40}
                      color="#FFFFFF"
                      className="footer-icon-media"
                      onClick={() => goTo("https://github.com/InfoJr")}
                    />
                    <RiFacebookCircleLine
                      size={40}
                      color="#FFFFFF"
                      className="footer-icon-media"
                      onClick={() => goTo("https://pt-br.facebook.com/InfoJr/")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <p>© 2023 Info Jr. Todos os direitos reservados</p>
        </div>
      </div>
    </div>
  );
}
