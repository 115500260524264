import React from "react";
import "./Home.css";
import UseWindowSize from "../../assets/functions/UseWindowSize/UseWindowSize";

//svg
import svgWaves from "../../assets/svgs/waves.svg";
import svgWaves1 from "../../assets/svgs/wavesPageIntro.svg";

//components
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import LandingPage from "./LandingPage/LandingPage";
import Intro from "./Intro/Intro";
import Servicos from "./Servicos/Servicos";
import ICampus from "./BanneriCampus/BanneriCampus";
import Contato from "../../components/Contato/Contato";

export default function Home() {
  const size = UseWindowSize();

  return (
    <React.Fragment>
      {size.width >= 992 ? (
        <div className="container-gradient">
          <img
            src={svgWaves}
            alt=""
            width="100%"
            className="img-waves"
            draggable="false"
          />
          <NavBar />
          <LandingPage />
          <Intro />
        </div>
      ) : (
        <React.Fragment>
          <div className="container-gradient">
            <img
              src={svgWaves1}
              alt=""
              width="100%"
              className="img-waves"
              draggable="false"
            />
            <NavBar />
            <LandingPage />
          </div>
          <Intro />
        </React.Fragment>
      )}
      <Servicos />
      <ICampus />
      <Contato />
      <Footer />
    </React.Fragment>
  );
}
