import React from "react";
import "./CardMembro.css";
import { RiGithubFill } from "react-icons/ri";
import { RiLinkedinBoxLine } from "react-icons/ri";

export default function CardMembro(props) {
  return (
    <div className="card-shadow container-member text-center">
      <div className="container overflow">
        <img
          className="card-img-top"
          src={props.img}
          alt={props.name}
          draggable="false"
        />
      </div>
      <div className="card-body">
        <h5>{props.name}</h5>
        <p>{props.role}</p>
        <ul className="card-icons justify-content-center ">
          <li className="row">
            <a href={props.github} target={"_blank"}>
              <RiGithubFill className="p-1" size={55} color="#112969" />
            </a>
            <a href={props.linkedin} target={"_blank"}>
              <RiLinkedinBoxLine className="p-1" size={55} color="#112969" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
