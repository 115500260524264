import React from "react";
import { Link } from "react-router-dom";

//svgs
import svgGirlRocket from "../../../assets/svgs/girlRocket.svg";

export default function Intro() {
  return (
    <div className="container-fluid container-content py-3 py-md-5" id="intro">
      <div className="row pb-3">
        <div className="col-lg-5 col-sm-4 d-none d-sm-flex">
          <img
            src={svgGirlRocket}
            alt="Foguete"
            width="100%"
            draggable="false"
          />
        </div>
        <div className="col-lg-6 col-sm-8 offset-lg-1 mt-5 mt-md-0">
          <h1>
            Vantagens da
            <span style={{ color: "#2d95a9" }}> I</span>
            <span style={{ color: "#2a9da0" }}>n</span>
            <span style={{ color: "#27a697" }}>f</span>
            <span style={{ color: "#25af8e" }}>o</span>
            <span style={{ color: "#22b885" }}>J</span>
            <span style={{ color: "#20c17c" }}>r</span>
            <span style={{ color: "#1dca73" }}>.</span>
          </h1>
          <div className="container-fluid px-0 py-3 py-md-5">
            <ul className="text-justify p-0">
              <li>Confiabilidade e eficiência para o seu projeto.</li>
              <li>
                Há 30 anos trazendo serviços de qualidade para nossos
                clientes.
              </li>
              <li>
                Atendimento e comunicação rápida para você não perder nenhuma
                alteração.
              </li>
            </ul>
          </div>
          <Link to="/sobrenos">
            <button type="button" className="button">
              Saiba Mais
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
