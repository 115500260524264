import React from "react";
import "./Contato.css";
import UseWindowSize from "../../assets/functions/UseWindowSize/UseWindowSize";
import Helmet from "react-helmet";

//svgs
import svgContato from "../../assets/svgs/contato.svg";

export default function Contato() {
  const size = UseWindowSize();

  const adFunction = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "AW-825884306");

    gtag("event", "conversion", {
      send_to: "AW-825884306/YnoQCNb0kcQBEJL954kD",
    });
  };

  return (
    <div
      className="container-fluid container-content text-center py-5"
      id="contato"
    >
      <Helmet>
        <script>{`${adFunction}`}</script>
      </Helmet>
      <div className="row">
        <div className="col-12 mb-3 mb-md-5">
          <h1>Vamos conversar!</h1>
        </div>
      </div>
      {size.width >= 400 ? (
        <div className="row">
          <div className="col-lg-5 d-none d-lg-block">
            <h1>Faça suas ideias acontecerem</h1>
            <img
              src={svgContato}
              alt=""
              width="100%"
              className="mt-5"
              draggable="false"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <div className="card-shadow embed-container">
              <iframe
                scrolling="no"
                title="form"
                src="https://app.pipefy.com/public/form/_8bv5z5m?embedded=true"
                width="100%"
                height="680"
                frameBorder="0"
              ></iframe>
              <div className="open-form">
                <p>
                  Com dificuldade de visualizar o formulário de contato? Mande
                  uma mensagem por{" "}
                  <a
                    href="https://app.pipefy.com/public/form/_8bv5z5m"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    aqui
                  </a>
                  !
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="embed-container">
              <iframe
                scrolling="no"
                title="form"
                src="https://app.pipefy.com/public/form/_8bv5z5m?embedded=true"
                width="100%"
                height="700"
                frameBorder="0"
              ></iframe>
            </div>
            <div className="open-form">
              <p>
                Com dificuldade de visualizar o formulário de contato? Mande uma
                mensagem por{" "}
                <a
                  href="https://app.pipefy.com/public/form/_8bv5z5m"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>
                !
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
