import React from "react";
import Slider from "react-slick";
import "./Portfolio.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

export default function Portfolio(props) {
  const settings = {
    dots: false,
    prevArrow: <RiArrowLeftSLine color="112969"></RiArrowLeftSLine>,
    nextArrow: <RiArrowRightSLine color="112969"></RiArrowRightSLine>,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  return <Slider {...settings}>{props.children}</Slider>;
}
